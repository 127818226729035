.imgStyles {
    justify-self: start;
    cursor: pointer;
}

.img-sizing {
    height: 100px;
    width: 150px;
    margin-bottom: 1em;
}

.fa-bars,
.fa-times {
    color: #fff;

}

.gradient__texts {
    background: linear-gradient(to right, #FFB81C 29%, #046A38 29%, #046A38 69%, #BE3A34 69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
}

.menu-icons {
    display: none;
}

.fa-headphones {
    color: #fff;
}

.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    margin-bottom: 2rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i {
    padding-right: 10px;
}

.nav-links:hover {
    background: rgb(5, 28, 63);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 3px;
    text-decoration: none;
    background-color: #fafafa;
    font-size: 1rem;
    font-weight: 1rem;
    color: #222;

}

@media screen and (max-width: 850px) {
    .NavbarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #c5c5c9;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
        backdrop-filter: blur(4px);
        border-radius: 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .nav-menu.active {
        left: 0%;
    }

    .nav-links {
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: #222;
    }

    .nav-links:hover {
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(20px);
    }

    .menu-icons {
        display: block;
    }

    .nav-links-mobile {
        padding: 1.5rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }
}