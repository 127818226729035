* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
}

body {
    height: 100vh;
    width: 100%;
}

.container {
    margin-top: 6rem;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 100px;
}

.container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    filter: blur(50px);
    z-index: -1;
}

.contact-box {
    max-width: 75%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.19);
}

.videoTag {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.left {
    object-fit: cover;
    height: 100%;
}

.right {
    background: url("headphones.jpg") no-repeat center;
    background-size: cover;
    /*this your primary color*/
    padding: 30px 40px;
}

h2 {
    font-family: var(--font-family);
    position: relative;
    padding: 0 0 10px;
    margin-bottom: 10px;
    color: white;
}

h2:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
}

.field {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0);
    outline: none;
    background-color: rgba(230, 230, 230, 0.904);
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    margin-bottom: 22px;
    transition: .3s;
    border-radius: 25px;
}

.field:hover {
    background-color: rgba(230, 230, 230, 0.664);
}

textarea {
    min-height: 150px;
}

.btn {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: rgba(40, 75, 170, 0.47);
    color: #fff;
    font-size: 1.1rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .3s;
    border-radius: 25px;
}

.btn:hover {
    background-color: rgba(2, 20, 73, 0.47);
}

.field:focus {
    border: 2px solid rgba(30, 85, 250, 0.47);
    background-color: #fff;
}

@media screen and (max-width: 880px) {
    .container {
        margin-top: 6rem;
        padding: 20px 0px;
    }

    .contact-box {
        grid-template-columns: 1fr;
    }

    .left {
        height: 300px;
    }

    .videoTag {
        width: 100%;
        height: 300px;
        object-fit: cover;

    }
}