@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


@import url('https://fonts.cdnfonts.com/css/square-sans-serif-7');


:root {
    --font-family: 'Square Sans Serif 7', sans-serif;

    --gradient-text: linear-gradient(to right, #ff0000 72%, #ff0000 72%, #ff0000 72%, #ff0000 72%);
    --gradient-texts: linear-gradient(to right, #FFB81C 29%, #046A38 32%, #046A38 72%, #BE3A34 72%);
    --color-footer: #031B34;
    --color-blog: #042c54;
    --color-text: #c8d678;
    --color-subtext: #FF8A71;
}